import * as Constants from "../constants";
import i18n from "../i18n";

const languageHandler = {

    specifyMinskCity() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.CITY_MINSK_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.CITY_MINSK_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.CITY_MINSK_BE;
            default:
                return Constants.CITY_MINSK_RU;
        }
    },

    specifyAboutUsPostType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.POST_TYPE_ABOUT_US_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.POST_TYPE_ABOUT_US_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.POST_TYPE_ABOUT_US_BE;
            default:
                return Constants.POST_TYPE_ABOUT_US_RU;
        }
    },

    specifyAntiAwardPostType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.POST_TYPE_ANTI_AWARD_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.POST_TYPE_ANTI_AWARD_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.POST_TYPE_ANTI_AWARD_BE;
            default:
                return Constants.POST_TYPE_ANTI_AWARD_RU;
        }
    },

    specifyEduProgramEventType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.EVENT_TYPE_EDU_PROGRAM_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.EVENT_TYPE_EDU_PROGRAM_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.EVENT_TYPE_EDU_PROGRAM_BE;
            default:
                return Constants.EVENT_TYPE_EDU_PROGRAM_RU;
        }
    },

    specifyContestMovieType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.MOVIE_TYPE_CONTEST_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.MOVIE_TYPE_CONTEST_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.MOVIE_TYPE_CONTEST_BE;
            default:
                return Constants.MOVIE_TYPE_CONTEST_RU;
        }
    },

    specifyMovieNightMovieType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.MOVIE_TYPE_CINEMA_NIGHT_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.MOVIE_TYPE_CINEMA_NIGHT_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.MOVIE_TYPE_CINEMA_NIGHT_BE;
            default:
                return Constants.MOVIE_TYPE_CINEMA_NIGHT_RU;
        }
    },

    specifyOnlineMovieType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.MOVIE_TYPE_ONLINE_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.MOVIE_TYPE_ONLINE_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.MOVIE_TYPE_ONLINE_BE;
            default:
                return Constants.MOVIE_TYPE_ONLINE_RU;
        }
    },

    specifySpecialEventType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.EVENT_TYPE_SPECIAL_EVENT_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.EVENT_TYPE_SPECIAL_EVENT_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.EVENT_TYPE_SPECIAL_EVENT_BE;
            default:
                return Constants.EVENT_TYPE_SPECIAL_EVENT_RU;
        }
    },

    specifyCovidPostType() {
        switch (i18n.language) {
            case Constants.LANGUAGE_ENGLISH:
                return Constants.POST_TYPE_COVID_EN;
            case Constants.LANGUAGE_RUSSIAN:
                return Constants.POST_TYPE_COVID_RU;
            case Constants.LANGUAGE_BELARUSSIAN:
                return Constants.POST_TYPE_COVID_BE;
            default:
                return Constants.POST_TYPE_COVID_RU;
        }
    },

};

export default languageHandler;