import React from "react";
import {useTranslation} from "react-i18next";
import {DONATE, EMAIL, FACEBOOK, INSTAGRAM, PHONE, VKONTAKTE} from "../../constants";
import Cookies from 'universal-cookie';
import {NavLink} from "react-router-dom";

function Footer() {

    const {t, i18n} = useTranslation();

    function changeLanguage(code) {
        {/*TODO: review setting language with help of url, not cookies */}
        i18n.changeLanguage(code, (err, t) => {
            const cookies = new Cookies();
            cookies.set("language", code);
            window.location.reload();
        });
    }

    return (
        <footer className="page-footer pt-4 footer-wd">
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <img src={window.location.origin + "/logo/WDfooter.png"}
                             alt={"Watch Docs Belarus"} className="object-fit-cover"/>
                    </div>
                    <div className="col-md-8 footer-nav">
                        <div className="row">
                            <div className="col-md-4 col-6 mt-md-0 mt-3 footer-options">
                                <div className="footer-option">
                                    <NavLink to={'/program'}>{t('navbar.program')}</NavLink>
                                </div>
                            </div>
                            <div className="col-md-4 col-6 mt-md-0 mt-3 footer-options">
                                <div className="footer-option">
                                    <NavLink to={'/archive'}>{t('navbar.archive')}</NavLink>
                                </div>
                            </div>
                            <div className="col-md-4 col-6 mt-md-0 mt-3 footer-options">
                                <div className="footer-option">
                                    <NavLink to={'/about'}>{t('navbar.about')}</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end contacts">
                            <div className="col-md-4 col-12 mt-md-0 mt-3">
                                <div className="email">
                                    <span className="email-icon"/>
                                    <span>{EMAIL}</span>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 mt-md-0 mt-3 networks">
                                <span className="network-facebook">
                                    <a href={FACEBOOK}/>
                                </span>
                                <span className="network-instagram">
                                    <a href={INSTAGRAM}/>
                                </span>
                                <span className="network-vk">
                                    <a href={VKONTAKTE}/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center footer-languages mt-3">
                    <div onClick={() => changeLanguage('be')}>
                        {t('global.belarussian')}
                    </div>
                    <div onClick={() => changeLanguage('ru')}>
                        {t('global.russian')}
                    </div>
                    <div onClick={() => changeLanguage('en')}>
                        {t('global.english')}
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">
                {/*TODO: put in system properties table */}
                © WATCH DOCS 2022
            </div>
        </footer>
    );
}

export default Footer;