import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import english from "./config/locales/en/translation.json";
import russian from "./config/locales/ru/translation.json";
import belarussian from "./config/locales/be/translation.json";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: english
    },
    ru: {
        translation: russian
    },
    be: {
        translation: belarussian
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: "ru",

        keySeparator: ".",

        interpolation: {
            escapeValue: false
        },

        detection: {
            lookupCookie: 'language'
        }
    });

i18n.changeLanguage();

export default i18n;