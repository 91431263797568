import React from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import '../../styles/navbar.scss';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {FESTIVAL_YEAR_2020, FESTIVAL_YEAR_2019} from "../../constants";

function NavBar() {
    const {t, i18n} = useTranslation();

    function changeLanguage(code) {
        {/*TODO: review setting language with help of url, not cookies */
        }
        i18n.changeLanguage(code, (err, t) => {
            const cookies = new Cookies();
            cookies.set("language", code);
            window.location.reload();
        });
    }

    const navLinks = [
        {id: "1", url: `/program2022`, label: "navbar.program", displayCondition: true},
        {id: "2", url: `/about`, label: "navbar.about", displayCondition: true},
        {id: "5", url: `/${FESTIVAL_YEAR_2020}/program`, label: "navbar.archive", displayCondition: true, type: "dropdown",
            dropdownItems: [
                {id: "1", url: `/${FESTIVAL_YEAR_2020}/program`, label: `${FESTIVAL_YEAR_2020}`, displayCondition: true},
                {id: "2", url: `/${FESTIVAL_YEAR_2019}/program`, label: `${FESTIVAL_YEAR_2019}`, displayCondition: true},
            ]
        }
    ];

    const navItems = (list) => (
        list.map(item => {
            if (item.displayCondition) {
                switch (item.type) {
                    case "dropdown" : { return dropdownNavItem(item); }
                    default : { return defaultNavItem(item); }
                }
            }
            return null;
        })
    );

    const dropdownNavItem = (item) => {
        return (
            <div className="nav-item dropdown" key={item.id}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                   data-toggle="dropdown" aria-expanded="false">
                    {t(item.label)}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    {dropdownItem(item.dropdownItems)}
                </div>
            </div>
        );
    };

    const dropdownItem = (dropdownItem) => (
        dropdownItem.map(item => {
            return (
                <div className="dropdown-item " onClick={closeMenu} key={item.id}>
                    <NavLink className="nav-link" to={item.url}>{t(item.label)}</NavLink>
                </div>
            );
        })
    );

    const defaultNavItem = (item) => {
        return (
            <div className="nav-item" onClick={closeMenu} key={item.id}>
                <NavLink className="nav-link" to={item.url}>{t(item.label)}</NavLink>
            </div>
        );
    };

    function toggleMenu() {
        $('body').toggleClass('lock-scroll');
        $('.navbar-root').toggleClass('show');
        $('.navbar-trigger').toggleClass('active');
    }

    function closeMenu() {
        $('body').removeClass('lock-scroll');
        $('.navbar-root').removeClass('show');
        $('.navbar-trigger').removeClass('active');
        $('#navbarCollapse').removeClass('show');
    }

    return (
        <div className="navbar-root">
            <nav className="navbar navbar-expand-lg navbar-lg">
                <div className="navbar-logo" onClick={closeMenu}>
                    <NavLink className="nav-link" to="/">
                        <img src={window.location.origin + "/logo/WDlogo_black.png"} alt={"Watch Docs Belarus"}
                             className="nav-logo object-fit-cover"/>
                    </NavLink>
                </div>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse"
                        onClick={toggleMenu}>
                    <span className="navbar-toggler-icon navbar-trigger">
                        <i></i><i></i><i></i>
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav mr-auto underscore-hover">
                        {navItems(navLinks)}
                    </div>
                    <div className="navbar-nav ml-auto">
                        <div className="nav-item nav-languages" onClick={closeMenu}>
                            <div className="nav-language" onClick={() => changeLanguage('be')}>
                                БЕЛ
                            </div>
                            <div className="nav-language" onClick={() => changeLanguage('ru')}>
                                РУС
                            </div>
                            <div className="nav-language" onClick={() => changeLanguage('en')}>
                                ENG
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
