import React, {Suspense} from "react";
import NavBar from "../components/navbar/navbar";
import Footer from "../components/footer/footer"
import Routes from "../components/routes/routes"
import LoadingIndicator from "../components/utils/loading/loadingIndicator";

function Main() {
    return (
        <div className="main" style={{"min-height": "100vh"}}>
            <NavBar/>
            <Suspense fallback={LoadingIndicator()}>
                <Routes/>
            </Suspense>
            <Footer/>
        </div>
    );
}

export default Main;