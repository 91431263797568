const LoadingIndicatorHelper = {
    getStyle() {
        return {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            margin: "auto",
            opacity: "1",
            backgroundColor: "#F7F2F3",
            zIndex: "100"
        };
    }
}

export default LoadingIndicatorHelper;