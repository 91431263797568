import React from "react";
import ReactDOM from "react-dom";
import Main from "./pages";
import {BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/main.scss';
import './i18n';
import {createNetworkStatusNotifier} from 'react-apollo-network-status';
import {ApolloProvider} from "@apollo/client";
import client from "./utils/apolloClient";
import LoadingIndicator from "./components/utils/loading/loadingIndicator";
const {useApolloNetworkStatus} = createNetworkStatusNotifier();

function GlobalLoadingIndicator() {
    const status = useApolloNetworkStatus();

    if (status.numPendingQueries > 0) {
        return <LoadingIndicator/>
    } else {
        return null;
    }
}

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <GlobalLoadingIndicator/>
            <Main/>
        </Router>
    </ApolloProvider>,
    document.getElementById("root")
);
