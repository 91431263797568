export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_RUSSIAN = 'ru';
export const LANGUAGE_BELARUSSIAN = 'be';

export const CITY_MINSK_EN = 1;
export const CITY_MINSK_BE = 2;
export const CITY_MINSK_RU = 3;

export const HOME_MOVIES_LIMIT = 8;

export const HOME_EVENTS_LIMIT = 4;

export const REQUEST_METHOD_GET = "GET";
export const REQUEST_METHOD_POST = "POST";

export const CURRENT_FESTIVAL_YEAR = 2022;
export const FESTIVAL_YEAR_2020 = 2020;
export const FESTIVAL_YEAR_2019 = 2019;

export const DEFAULT_DATETIME_FORMAT = "DD MMM, HH:mm";
export const DEFAULT_TIME_OPTIONS = "HH:mm";
export const SCREENING_DATE_OPTIONS = "DD MMM YYYY";
export const SCREENING_TIME_OPTIONS = "dddd, HH:mm";
export const SHORT_DATE_OPTIONS = "MMM DD";

export const DEFAULT_PROGRAM_DATE_FROM = "2020/12/8";
export const DEFAULT_PROGRAM_DATE_TO = "2020/12/13";

export const DEFAULT_REGIONS_DATE_FROM_2019 = "2019/10/4";
export const DEFAULT_REGIONS_DATE_TO_2019 = "2019/12/1";


export const FACEBOOK = 'https://www.facebook.com/watchdocsbelarus/';
export const FACEBOOK_FRAME = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatchdocsbelarus%2F&tabs=timeline&show_facepile=true&appId=729406323914592';
export const INSTAGRAM = 'https://www.instagram.com/watchdocsbelarus/';
export const VKONTAKTE = 'https://vk.com/watchdocsbelarus';
export const YOUTUBE = 'https://www.youtube.com';
export const YOUTUBE_HOSTING = 'www.youtube.com';
export const DONATE = 'https://www.talaka.org/projects/3568/fund';
export const VIMEO_HOSTING = 'vimeo.com';
export const VIMEO = 'https://player.vimeo.com';

export const TARGET_BLANK = '_blank';

export const PHONE = "+375(29)7760634";
export const EMAIL = "watchdocsbelarus@gmail.com";

export const EVENT = "event";
export const MOVIE = "movie";

export const POST_TYPE_ABOUT_US_EN = 1;
export const POST_TYPE_ABOUT_US_RU = 2;
export const POST_TYPE_ABOUT_US_BE = 3;

export const POST_TYPE_ANTI_AWARD_EN = 4;
export const POST_TYPE_ANTI_AWARD_RU = 5;
export const POST_TYPE_ANTI_AWARD_BE = 6;

export const POST_TYPE_COVID_RU = 7;
export const POST_TYPE_COVID_EN = 8;
export const POST_TYPE_COVID_BE = 9;

export const EVENT_TYPE_EDU_PROGRAM_EN = 1;
export const EVENT_TYPE_EDU_PROGRAM_RU = 2;
export const EVENT_TYPE_EDU_PROGRAM_BE = 3;

export const EVENT_TYPE_SPECIAL_EVENT_EN = 4;
export const EVENT_TYPE_SPECIAL_EVENT_RU = 6;
export const EVENT_TYPE_SPECIAL_EVENT_BE = 5;

export const MOVIE_TYPE_CONTEST_EN = 7;
export const MOVIE_TYPE_CONTEST_RU = 8;
export const MOVIE_TYPE_CONTEST_BE = 9;

export const MOVIE_TYPE_CINEMA_NIGHT_EN = 6;
export const MOVIE_TYPE_CINEMA_NIGHT_RU = 5;
export const MOVIE_TYPE_CINEMA_NIGHT_BE = 4;

export const MOVIE_TYPE_ONLINE_EN = 15;
export const MOVIE_TYPE_ONLINE_RU = 13;
export const MOVIE_TYPE_ONLINE_BE = 14;