import React from "react";
import Loader from "react-loader-spinner";
import loadingIndicatorHelper from "./loadingIndicatorHelper";

function LoadingIndicator() {

    return (
        <div style={loadingIndicatorHelper.getStyle()}>

            <Loader type="Oval" color="#F55152" height="80" width="80"/>
        </div>
    );
}

export default LoadingIndicator;
