import React, {lazy, useLayoutEffect} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import languageHandler from "../../utils/languageHandler";

const Archive = lazy(() => import('../../pages/archive'));
const Home = lazy(() => import('../../pages/home'));
const MassMedia = lazy(() => import('../../pages/massMedia'));
const Movie = lazy(() => import('../../pages/movie'));
const Event = lazy(() => import('../../pages/event'));
const NotFound = lazy(() => import('../../pages/notFound'));
const NoTimelineProgram = lazy(() => import('../../pages/program/noTimelineProgram'));
const Post = lazy(() => import('../../pages/post'));


export default function Routes() {

    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className="content">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/program2022" component={NoTimelineProgram}/>
                <Route path="/movies/:slug" component={Movie}/>
                <Route path="/events/:slug" component={Event}/>
                <Route path="/about" key="about-us"
                       render={() => <Post postTypeID={languageHandler.specifyAboutUsPostType()}/>}/>
                <Route path="/mass-media" component={MassMedia}/>
                <Route path="/covid-19" key="covid-19"
                       render={() => <Post postTypeID={languageHandler.specifyCovidPostType()}/>}/>
                <Route path="/:year(2019|2020)" render={(props) => (
                    <Archive key={props.match.params.year} {...props} />
                )} />

                <Route path="/not-found" component={NotFound}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        </div>
    );
}